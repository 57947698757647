@import url("https://use.typekit.net/itm4uwk.css");
@import "../../node_modules/bourbon/core/bourbon";
// @import "../sasslibs/bitters/base";
@import "../../node_modules/bourbon-neat/core/neat";
@import "../sasslibs/_variables";
@import "../sasslibs/_mixins";
@import "../sasslibs/fontawesome/fontawesome.scss";
@import "../sasslibs/fontawesome/solid.scss";
@import "../sasslibs/fontawesome/brands.scss";
@import "../sasslibs/fontawesome/regular.scss";
@import "../sasslibs/fontawesome/light.scss";
@import "../sasslibs/fontawesome/duotone.scss";

@mixin icon($icon) {
	@include fa-icon;
	@extend .fas;
	@extend .fad;
	@extend .fa-#{$icon};
}

html,
body {
	height: auto;
	min-height: 100%;}
	
body {
	position: relative;
	font-family: $font-family-base;
	margin: 0px;
	color: $font-base-color;
	background-color: $background-color;
}

body::after {
	content: "";
	background-image: url('/assets/img/concrete_seamless/concrete_seamless.png');
	background-repeat: repeat;
	opacity: 0.35;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}

body.snapar-new{
// 	background-color: white;
}
body.locked {
	background-color: $menu-color-dark;

	.nav-wrap {
		background-color: $menu-color-dark;
	}
	footer {
		background-color: $menu-color-dark;
	}
}
.content {
	max-width: $page-width;
	@include desktop {
		margin: 0px auto;
		width: 100%;
	}
	position: relative;
	z-index: 10;
}

h1, h2, h3, h4, h5 {
	font-family: $font-family-headline;
	color: $font-color-headline;
}

h1 { 
	font-weight: 100;
	font-size: $font-size-h1 * .55;	
	@include desktop {
		font-size: $font-size-h1;	
	}
}
h2 {
	font-size: $font-size-h2 * .75;	
	@include desktop {
		font-size: $font-size-h2;	
	}
	
}
h3 { 
	font-size: $font-size-h3 *.75;
	@include desktop {
		font-size: $font-size-h3;
	}
}

p { 
	font-size: $font-size-base; 
	color: $font-color-base;
	@include desktop {
		font-size: $font-size-base;
	}
}

hr {
	border: 0;
    height: 1px;
    background: $base-color;
    background-image: linear-gradient(to right, $sec-color-light, $sec-color, $sec-color-light);
}

ol {
	list-style-type: decimal;
	margin-left: 1.5rem;
	font-size: $font-size-base;
	margin-bottom: 1rem;
	font-family: $font-family-base;
	> li {
		padding-left: .25rem;
		line-height: 1.5rem;
	}
}

a {
	text-decoration: none;
	color: $base-color-light;
}

#{$all-buttons} {
	background-color: $highlight-color-light;
	border-radius: $border-radius-base;
	border: 1px solid $highlight-color;
	padding: 10px 10px 5px 10px;
	margin: 4px;
	letter-spacing: 1px;
	font-size: $font-size-large*1.15;
	font-family: $font-family-headline;
	color: $base-color;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	-webkit-appearance: none;
	h2, h3, h4 {
		margin: 0px;
	}
}

#{$all-buttons-focus},
#{$all-buttons-hover} {
	background-color: $highlight-color;
	cursor: pointer;
	color: white;
	transition: 0.5s ease;
}

#{$all-buttons-active} {
	background-color: $highlight-color;
	cursor: pointer;
	color: white;
}

/*
#{$all-buttons}:disabled {
	color: transparentize($base-color, 0.5);
	background-color: transparentize($highlight-color, 0.5);
	border: 1px solid transparentize($highlight-color, 0.5);
	cursor: default;
}
*/

#{$all-buttons-focus}:disabled,
#{$all-buttons-hover}:disabled {
	
}

//#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { font:400 12px/16px $font-family-sans-serif2, Helvetica, Arial, sans-serif; }
button.search {
// 	-webkit-appearance: none;
	font-weight: 600;
	font-size: $font-size-base*1.4;
	font-family: $font-family-headline;
	margin-left: -3px;
	padding-top: 11px;
	padding-bottom: 10px;
	@include border-left-radius(0px);
}
#{$all-text-inputs} {
	-webkit-appearance: none;
	border:1px solid #CCC;
	background:#FFF;
	width:100%;
	margin:0 0 5px;
	padding:10px;
	font-size: $font-size-base*1.3;
	font-family: $font-family-base;
	
	&:hover {
		-webkit-transition:border-color 0.3s ease-in-out;
		-moz-transition:border-color 0.3s ease-in-out;
		transition:border-color 0.3s ease-in-out;
		border:1px solid #AAA;
	}
}

.inputError {
	border: 1px solid $red !important;
}

.inputError::-moz-placeholder-shown {
	color: red;
	}


.searchInput {
	-webkit-appearance: none;
	padding: 8px 5px 12px 10px;
	border-right: 0px;
	width: 55%;
	margin-right: 0px;
	font-size: $font-size-base;
	@include tablet {
		width: 35%;
	}
	@include desktop {
		width: 35%;
	}
}

.app-icon img {
	border-radius: 25%;
}

.loginForm {
	max-width: 15rem;
}

a.contact {
	font-weight: bold;
}

.errors {
	color: red;
	margin-top: 0px;
	li {
		list-style: none;
	}
}

ul.errors {
	margin-left: 0px;
	padding-left: 0px;
}

.header {
	background: white;
	padding: 0px;
	margin: 0px;
	box-shadow: 0px -10px 20px $base-color-dark;
	@include grid-container;
}

.dc {
	font-family: $font-family-dc;
	margin-bottom: 0px;
}

.center-flex {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.hidden {
	display: none;
}

.fitted-image {
	object-fit: cover; 
}
.fit-top-center {
	object-position: top center; 
}
.fit-center-center {
	object-position: center center; 
}
.fit-bottom-center {
	object-position: bottom center; 
}

// ******************* //
// **** MAIN MENU **** //
// ******************* //

.nav-wrap{
	position: relative;
	background: $menu-color;
	z-index: 100000000!important;
	box-shadow: 0px 0px 30px $sec-color-dark;
	
}

nav {
	@include container;
	@include iphone-notch-left;
	font-family: $menu-font-family;
	margin-bottom: 0px;
	> ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-size: 0;    
		text-align: left;
		background: $menu-color;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> li {
			display: inline-block;
			flex-direction: column;
			position: relative;
			z-index: 100000000!important;
			
			&.hidden {
				display: none;
				visibility: visible;
			}

			&.children {
				.showMore {
					visibility: visible;
					i {
						animation: none;
						font-size: $font-size-h2;
						margin: 0px;
						margin-top: -.4rem;
						color: $menu-font-color;
						text-shadow: 0px 1px 2px transparentize($menu-color, 0.2);
					}
				}
				
				&:hover, &:active, {
					li {
						a{
							max-height: inherit;
							.showMore {
								display: none;
							}
						}
					}
				}

			}

			> a {
				border-right: 1px solid lighten($menu-color,25%);
				.showMore {
					visibility: hidden;
				}
			}

			.logo {
				
				border-right: 1px solid lighten($menu-color,25%);
				min-width: 5.5rem;				
				font-size: 1.2rem;
				padding: 5px 0px 0px 0px;
// 				padding: 0px;
				@include not-phone {
					padding-left: 0px;
				}
				@include desktop {
					padding-bottom: 10px;
				}
			}
			.logo img {
				max-height: 3.75rem;
// 				width: 300px;
				margin: 0px;
				margin-left: -.5rem;
// 				margin-bottom: -5px;
				padding-top: 4px;
				padding-bottom: 4px;
				@include desktop {
					padding-top: 0px;
					padding-bottom: 0px;
					width: 215px;
				}
			}
			a {
				max-height: 3.75rem;
				white-space: nowrap;
				font-size: .95rem;
				display: block;
				background: $menu-color;
				color: $menu-font-color;
				text-align: center;
				text-decoration: none;
				padding: 5px 10px;
				@include desktop {
					max-height: 4.75rem;
					padding: 10px 20px;
					font-size: 1.2rem;
				}
				.fas, .far, .fal, .fad {
					font-size: 20px;
					display: block;
					margin-bottom: 5px;
					color: $menu-icon-color;
					@include desktop {
						margin-bottom: 10px;
					}
				}

				+ ul {
					list-style: none;
					visibility: hidden;
					opacity: 0;
					background: none;
					position: absolute;
					top: 100%;
					right: 0;
					margin-right: 0;
					padding-top: 1px;
					padding-left: 0px;
					li {
						margin-top: 2px;
						
						a {
							box-shadow: 0px 0px 30px $sec-color-dark;
							padding-left: 16px;
							text-align: left;
							white-space: nowrap;
							max-height: inherit;
							.fas, .far, .fal, .fad {
								display: inline-block;
								width: 2rem;
								margin-right: 10px;
								margin-bottom: 0;
								margin: 0px auto;
							}
							.title {
								display: inline-block;
								text-align: left;
							}
							.title-wrap .title {
								display: block;
								text-align: center;
							}
							.showMore {
								display: none;
							}
						}
						a:hover {
							background: transparentize(darken($menu-color,15%),0.05);
						}
						> ul {
							list-style: none;
							padding-bottom: 0px;
							position: relative;
							top: 0;
							right: 0;
							>li {
								margin-top: 1px;
// 								padding-left: 1rem;
								border-left: 1rem solid darken($menu-color,5%);
								a {
									display: block;
									.fas, .far, .fal, .fad {
										color: $menu-font-color;
									}
									img {
										display: block;
										margin: 0px auto;
										margin-top: .5rem;
										max-width: 80%;
									}
									.title {
										width: 100%;
									}
	
								}
								display: block;
							}
							@include phone {
							>li {
								a {
									img {
										display: block;
										margin: 0px auto;
										margin-top: .5rem;
										max-width: 25%;
									}
								}
							}
							}
						}
						> ul::before {
							box-shadow: none;
						}


					}
				}
			}

			&:hover, &:active, {

				ul {
					display: block;
					visibility: visible;
					opacity: 1;
					li {
						img, .fas, .far, .fal, .fad {
							animation: none;
						}
						> ul {
							visibility: visible;
							opacity: 1;
						}
						&:hover, &:active, &:focus {
							> a {
								color: $font-action-color;
								img, .fas, .far, .fal, .fad {
									animation: nudge 1.5s ease-out infinite;
								}
								.fas, .far, .fal, .fad {
									color: $font-action-color;
								}
							}
						}
					}
				}
				
				> a {
					background: darken($menu-color,15%);
					color: $font-action-color;
					.showMore {
						i {
							color: $font-action-color;
						}
					}
					+ ul {
						> li {
							img, .fas, .far, .fal, .fad {
								animation: none;
							}
							.fas, .far, .fal, .fad {
								color: $menu-icon-color;
							}
						}
					}
				}
				
				img, .fas, .far, .fal, .fad {
					animation: nudge 1.5s ease-out infinite;
				}
				.fas, .far, .fal, .fad {
					color: $font-action-color;					
				}

			}
		}
		
		li#home-nav {
			display: none;
			@include desktop {
				display: inline-block;
			}
		}
	}
	
	> ul#home {
		>li > a > i {
			@include desktop {
				font-size: 2rem;
			}
		}
	}
	
}

nav.links {
	background-color: white;
	display: flex;
	flex-flow: row wrap;
	background-color: white;
	max-width: none;
	width: 100%;
	> ul {
		padding-left: 0rem;
		flex-flow: row wrap;
		background-color: white;
		width: 100%;

		> li {
			background-color: white;
			font-size: 1rem;
			padding-left: 0rem;
			padding-right: 1.5rem;
			a {
				background-color: white;
				font-size: 1rem;
			}
		}
	}
}



nav.page-menu {
	margin: 0px;
	margin-left: 10px;
	margin-bottom: 4em;
	padding: 0px;
	display: flex;
	
	.navitem {
		font-family: $font-family-sans-serif2;
		font-weight: 900;
		color: $font-color-headline;
		font-size: $font-size-h4;
		margin-right: 10px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;

		a:hover {
			color: $base-color-dark;

		}
	}
}

hr.header {
	width: 100%;
	height: 12px;
	border: 0;
	background: transparentize($background-color, 0.0);
	box-shadow: inset 0 12px 5px -12px $base-color-light;
	margin-bottom: -12px;

}
hr.header::after {
}
	
header.sub-header {
		//height: 100px;
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 10px;
		img {
			max-width: 85px;
			@include phone-landscape {
				max-width: 85px;
			}
			@include tablet {
				max-width: 120px;
			}
			@include desktop {
				max-width: 130px;
			}
		}
		.logo{
			margin-left: 0px;
			@include grid-column(4);
			align-items: center;
			text-align: center;
			@include phone-landscape {
				margin-top: 0px;
			}
			@include tablet {
				margin-top: 10px;
				@include grid-column (2);
			}
			@include desktop {
				@include grid-column(2);
				margin-top: 10px;
			}
		}
		
		.title {
			margin-top: 0px;
			padding-left: 0px;
			
			@include grid-column (8);

			@include phone-landscape {
				margin-top: 0px;
				padding-left: 0px;
			}

			@include tablet {
				margin-top: 15px;
				@include grid-column (9);
			}

			@include desktop {
				@include grid-column (8);
				margin-top: 22px;
			}
			h1.name {
				margin-top: 0px;
				font-family: $font-family-apple;
				font-weight: 700;
				margin-top: 0px;
				margin-bottom: 5px;
				width: 100%;
				@include tablet {
					margin-left: 0px;
	// 				font-size: 1em;
				}
				@include desktop {
					margin-left: 0px;
					font-size: 3em;
				}
			}
		}
		.subtitle {

			
			h2 {
				font-size: 1em;
				padding-left: 0px;
				margin-left: 0px;
				margin-top: .25em;
				@include phone-landscape {
					font-size: 1.5em;
				}
				@include tablet {
					font-size: 1.65em;
				}
				@include desktop {
	
					font-size: 2em;
				}
	
			}
		}
		
		h3.page-title {
			float: right;
			margin-right: 10px;
			margin-top: 10px;
		}
	
}

header.page {
	@include grid-container;
	margin-left: 1em;
	h1 {
		margin-bottom: .2em;
	}
}

.float-right {
	float:right;
}

.float-left {
	float: left;
}

// *********************** //
// *** PARALLAX HEADER *** //
// *********************** //
.header-background {
	position: absolute;
	// top: 25px;
	left: 0px;
	width: 100%;
	height: 70%;
	z-index: 0;
	margin: 0;
	background: none;
	border-bottom: 5px solid $highlight-color-dark;

}
video.header-background {
	filter: brightness(60%) saturate(80%) contrast(110%);
	transition: ease-in-out;
	transition-duration: .5s;

}
body.podcast {

}
#header-background-image {
	filter: brightness(60%) ;
}
.header-background::after {
	display: block;
	position: absolute;
	content: "";
	height: 100%;
	top: 0px;
	z-index: 1;
	width: 100%;
	box-shadow: inset 0px 0px 150px $darkgray;
}

body.Home {
	.header-background-wrapper {
		height: 100vh;
		@include not-phone {
			height: 100vh;
		}		
		width: 100%;
		background-color: rgb(10,10,10);
		position: absolute;
		top: 0px;
		overflow: hidden;
		border-bottom: 5px solid $highlight-color-dark;

	}

	.header-background{
		top: 0px;
		height: 96vh;
		@include not-phone {
			height: 100vh;
		}
		display: block;
		justify-content: center;
		overflow: hidden;
		filter: opacity(50%);
	}
	.header-background::after {
		box-shadow: none;
// 		@include page-background-gradient;
	}
	
}

.highlight {
	$gradient-background-top: tint($base-color, 10%);
	$gradient-background-bottom: $base-color;
	$gradient-angle: 10deg;
	
	background: linear-gradient($gradient-angle, $gradient-background-bottom, $gradient-background-top), no-repeat $gradient-background-top scroll;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	padding-bottom: 2em;
	
	.content {
		section {
			article {
				@include tablet {
					margin-left: 10em;
					margin-right: 10em;
					color: white;
				}
				@include desktop {
					margin-left: 10em;
					margin-right: 10em;
					color: white;
				}
				h1,h2,h3,h4,h5,p {
					color: white;
				}
				h1 {
					line-height: $font-size-h1*1.5;
				}
				h2 {
					line-height: $font-size-h2*1.5;
				}
				h3 {
					line-height: $font-size-h3*1.5;
				}
				h4 {
					line-height: $font-size-h4*1.5;
				}
				h5 {
					line-height: $font-size-h5*1.5;
				}
				
			}
		}
	}
}
.highlight.first {
	margin-top: -10px;
	padding-bottom: 1em;
	padding-top: 1em;
	section {
		padding-top: 0;
		article {
			padding-top: 0;
			margin-left: 1rem;
			margin-right: 1rem;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			h1 {
				margin-top: 0;
				padding-top: 0;
				margin-right: 5rem;
			}
			.home-body {
				max-width: 20rem;
			}
		}
	}
}

$footer-grid-desktop: (
  columns: 12,
  gutter: 50px,
  media: $desktop-width,
);

.opener {
	background-color: white;
	width: 100%;

	article {
		margin: 0px auto;
		max-width: $page-width;
		padding: 10px 10px 20px 10px;

		p {
			font-size: 125%;
			
			a {
				color: $highlight-color-dark;
			}
		}
		
		@include tablet {
			p {
				font-size: 175%;
			}
		}
		@include desktop {
		
			p {
				margin-left: 2em;
				margin-right: 2em;
				margin-top: 2em;
				font-size: 175%;
			}
		}
	}
}
footer {
	position: relative;
	z-index: 10;
	background-color: white;
	border-top: 5px solid transparentize($highlight-color-light, 0.65);
	margin-top: 5em;
	@include padding(5rem null);
	text-align: center;
	font-size: $font-size-base;
	color: white;
	nav {
		@include padding(0px 0px);
		display: flex;
		justify-content: center;
		flex-direction: column;
		@include tablet {
			flex-direction: row;
		}
		@include desktop {
			flex-direction: row;
		}
		.navitem {
			

			@include padding(null 1rem);
			font-size: $font-size-large;
			
			@include tablet {
				width: inherit;
				font-size: $font-size-h3;
			}
			@include desktop {
				width: inherit;
				font-size: $font-size-h3;
			}
			
			a {
				color: $base-color;
			}
			
			a:hover {
				color: $base-color-light;
			}

		}
	}
	
	nav.social {
		display: flex;
		justify-content: center;
		align-content: center;
		margin-top: 1em;
		.navitem.social {
			
			@include phone-landscape {
// 				@include grid-column(4);
			}
			@include desktop {
// 				@include grid-column(4);
			}
			margin: 0px;
// 			padding-left: 2em;
			ul {
				list-style-type: none;
				margin: 0px;
				display: flex;
				align-content: center;
				justify-content: center;
				padding: 0px;
				overflow: hidden;
				margin-top: 5px;
				@include tablet {
					margin-top: 10px;
				}
				@include desktop {
					margin-top: 16px;
				}
				li {
// 					float: left;
				}
				li a {

// 					display: block;
// 					display: flex;
					padding: 0px;
					padding-right: 5px;

					img {
						height: 23px;

					}
				}
			}

		}
	}
	
	.copyright {
		display: block;
		width: 100%;
		@include clearfix;
		font-size: $font-size-small;
		text-align: center;
		color: $base-color-light;
	}
}

/******					******/
/******	  Video Loop 	******/
/******	  Animated Gif 	******/
/******					******/

.videoloop {
	border-radius: $border-radius-huge*1.5;
	overflow: hidden;
	width: 109px;
	height: 240px;
	float: right;
	margin-top: 1.75em;
	margin-left: 1em;
	border: 6px solid $darkgray;
	video {
		width: 111px;
		height: 240px;
		border-radius: $border-radius-huge*.90;
	}
	
	@include tablet {
		width: 222px;
		height: 480px;
		video {
			width: 222px;
			height: 480px;
		}
	}
	
	@include desktop {
		width: 221px;
		height: 480px;
		margin-left: 4em;
		border-radius: $border-radius-huge*1.75;
		video {
			border-radius: $border-radius-huge;
			width: 222px;
			height: 480px;
		}
	}
}


/******					******/
/******	  Video Hero 	******/
/******					******/

$size: 85;
$sizepercent: $size * 1%;
$sizevh: $size * 1vh;
.container {	
/*
	position: absolute;	
	top: (100% - $sizepercent) / 2;
	left: (100% - $sizepercent) / 2;
	right: (100% - $sizepercent) / 2;
	bottom: (100% - $sizepercent) / 2;
*/
	height: $sizevh;
	width: 100%;
	position: relative;	
	top: calc((100%) / 2);
// 	left: 0%;
// 	right: (100%) / 2;
	bottom: calc((100%) / 2);

	.overlay {
		z-index: 2;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0%;
		height: 100%;
		color: white;
		transition: 0.5s ease;
		p {
			color: white;
			font-size: 140%;
			text-shadow: 1px 3px 5px black;
			
		}
		a {
			color: $highlight-color;
		}
		
		@include tablet{
			width: 50%;
			left: 25%;	  
		}
		@include desktop{
			width: 50%;
			left: 25%;
		}
	}
	

}


.container:before {
	content: '';
	background: $base-color;
	opacity: .4;
	z-index: 2;
	position: absolute;
	width: 100%;
	height: 100%;
// 	box-shadow: 0 0 0 10px red;
	pointer-events: none;
	transition: 0.5s ease;

}
.container:hover:before {
	opacity: 0;
	transition: 0.5s ease;
}



.video-bg {
	background: black;
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: 1;
	overflow: hidden;
}

.video-bg .video-fg,
.video-bg iframe,
.video-bg video {
	position: absolute;
// 	display: flex;
// 	top: -($sizepercent/2);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
}
@include phone {
	.container, .video-bg iframe {
	    max-height: calc($sizevh * 16 / 9 / 2);
	}
}
/*
  Full page video background
  Simulate object-fit: cover
  Based on http://fvsch.com/code/video-background/
*/

@media (min-aspect-ratio: #{"16/9"}) {
  .video-bg.cover .video-fg { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: #{"16/9"}) {
  .video-bg.cover .video-fg { width: 300%; left: -100%; }
}

@supports (object-fit: cover) {
  .video-bg.cover .video-fg.supports-cover {
    width: 100%;
    height: 100%;
    top: 0; 
    left: 0;
  }
  .video-bg.cover video {
    object-fit: cover;
  }
}


/*
  Vertical centering for 16/9 youtube iframes and video elements
  Simulate object-fit: contain for entire element, not just contents of element
*/

.video-bg.contain {
  font-size: 0;
}
.video-bg.contain * {
  font-size: 16px;
}
.video-bg.contain:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.video-bg.contain .video-fg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  pointer-events: none;
}
.video-bg.contain iframe,
.video-bg.contain video {
  pointer-events: auto;
}

@media (min-aspect-ratio: #{"16/9"}) {
  .video-bg.contain .video-fg {
    height: 100%;
    padding-bottom: 0;
    max-width: calc($sizevh * 16 / 9);
    left: 50%;
    margin-left: calc(($sizevh * 16 / 9) / -2);
  }
}

/******					******/
/******	  Audio Player 	******/
/******					******/

@keyframes rotate {
	0%		{transform: rotate(0deg);}
	100%	{transform: rotate(360deg);}
}

@keyframes fade-in {
	0%		{opacity: 0;}
	100%	{opacity: 1;}
}

.audio-player {
	$audioThemeColor: $menu-color-dark;
	$audioFontColor: tint($audioThemeColor, 80%);
	$audioBackgroundColor: transparentize($audioThemeColor, .5);
	$audioActionColor: tint($audioThemeColor, 70%);
	$audioControlsBackground: $audioThemeColor;
	$player-controls-height: 15px;
	@include not-phone {
		$player-controls-height: 25px;
	}
	@include desktop-lg {
		$player-controls-height: 35px;
	}
	background: $audioBackgroundColor;
	// border: 1px solid lighten($audioThemeColor, 20%);
	@include padding(.25rem);
	text-align: center;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	align-items: center;

	font-family: $font-family-headline;
	
	.album-image {
	min-height: 100px;
	width: 110px;
	background-size: cover;
	}

	
	.player-controls {
		align-items: stretch;
		background-color: $audioBackgroundColor;
		background: none;
		order: 1;
		width: 100%;
		@include margin(.5rem 1rem 0 1rem);

		progress {
			width: 100%;
			cursor: pointer;
			background-color: $audioControlsBackground;
			color: $audioThemeColor;
			border: 1px solid lighten($audioThemeColor, 20%);
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
		}
		
		progress::-moz-progress-bar {
			background-color: $audioActionColor;
		}
		progress[value] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			background-color: $audioControlsBackground;
			height: $player-controls-height;
			width: 100%;
		}
		
		progress::-webkit-progress-value {
			background-color: $audioActionColor !important;
		}

		progress[value]::-webkit-progress-bar {
			background-color: tint($audioControlsBackground, 20%);
			border-radius: 2px;
			border: 1px solid lighten($audioThemeColor, 20%);
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
		}

		$bg-color-ready: tint($audioThemeColor, 70%);
		$bg-color-ready-bar: tint($audioThemeColor, 50%);
		$bg-color-play: transparentize($audioThemeColor, 0.7);
		$bg-color-play-bar: transparentize($audioThemeColor, 0.5);
		progress[value]::-webkit-progress-value {
			position: relative;
			border-radius: 0px;
			border-right: 2px solid $bg-color-ready-bar;
			background: none;
			background-color: $bg-color-ready;
			background-position: 0px 0px;
		}
		
		&.playing progress[value]::-webkit-progress-value {
			background-color: $bg-color-play;
			border-right: 2px solid $bg-color-play-bar;
/*
				animation-name: animate-stripes;
				animation-duration: 75s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
*/
		}
		progress[value]::-webkit-progress-value:after {
			content: '';
			position: absolute;
			
			width:5px; height:5px;
			top:7px; right:7px;
			
			background-color: white;
			border-radius: 100%;
		}
				
		@keyframes animate-stripes {
			100% { 
				background-position: -3000px 0px; 
				border-right: 2px solid $bg-color-play;
			}
		}

		p {
			font-size: 1.6rem;
			color: $base-color;
		}
		.start-time, .end-time {
			opacity: 1.0;
			color: $audioFontColor;
			position: relative;
			z-index: 111111;
			margin-top: -.2rem;
			font-weight: 300;
			font-size: $font-size-base;
			@include not-phone {
				margin-top: 0rem;
				font-size: $font-size-base*1.1;
			}
// 			color: white;
		}
		.start-time {
			padding-left: .25rem;
			padding-top: 5px;
			margin-bottom: -16px;
			float:left;
		} 
		.end-time{
			padding-right: .25rem;
			padding-top: 5px;
			margin-bottom: -20px;
			float:right;
		}
		
		.tag {
			color: $audioFontColor;
			font-family: $font-family-base;
			@include padding(1.1rem .75rem .25rem .25rem);
			z-index: 100;
			font-weight: 100;
			font-size: $font-size-large;
			line-height: $font-size-large;
			font-weight: bold;
			@include desktop {
				font-size: $font-size-large*1.2;
			}
			.subtle {
			  color: tint($audioFontColor, 60%);
			}
		}
	}
	.play-btn, .rw-btn, .ff-btn, .next-btn, .prev-btn {
		color: $audioActionColor;
		cursor: pointer;
		&:hover {
			// color: $action-color-dark;
			color: tint($audioActionColor,25%);
		}
		&:active {	
			transform: scale(0.8);
		}
	}
	.rw-btn, .ff-btn, .next-btn, .prev-btn {
		font-size: 1.5rem;
		margin: .5rem 1rem 0rem 1rem;
		@include not-phone {
			font-size: 1.75rem;
		}
		@include phone-small {
			font-size: 1.25rem;
			margin: .25rem .5rem 0rem .5rem;
		}
	}
	.play-btn {
		margin: .5rem 1rem .35rem 1rem;
		font-size: 2.5rem;
		order: 4;
		@include not-phone {
			font-size: 3rem;
		}
		@include phone-small {
			font-size: 2rem;
		}
		&.pause {
			@extend %fa-icon;
			@extend .far;
			&:before {
				content: fa-content($fa-var-pause-circle);
			}
		}
		&.load {
			animation: rotate 2s linear 0s infinite;
			@extend %fa-icon;
			@extend .fas;
			&:before {
				content: fa-content($fa-var-spinner-third);
			}
		}
		&.ready {
			@extend %fa-icon;
			@extend .fas;
			&:before {
				content: fa-content($fa-var-play-circle);
			}
		}
	}

	.rw-btn {
		order: 3;
		@extend %fa-icon;
		@extend .far;
		&:after {
			border-radius: $border-radius-large;
			font-family: $font-family-headline;
			padding-right: 3px;
			font-weight: 600;
			// background-color: $audioBackgroundColor;
			background-color: $audioThemeColor;
			position: relative;
			font-size: .9rem;
			margin-top: 1rem;
			left: .9rem;
			content: "15";
			float: left;
			@include phone-small {
				font-size: .6rem;
				left: .6rem;
			}
		}
		&:before {
			content: fa-content($fa-var-undo-alt);
		}
	}
	.ff-btn {
		order: 5;
		margin-left: 0rem;
		@extend %fa-icon;
		@extend .far;
		&:before {
			content: fa-content($fa-var-redo-alt);
		}
		&:after {
			border-radius: $border-radius-large;
			font-family: $font-family-headline;
			font-weight: 600;
			// background-color: $audioBackgroundColor;
			background-color: $audioThemeColor;
			// -webkit-text-stroke: 1px $audioThemeColor;
			// -webkit-text-fill-color: $audioFontColor;
			padding-left: 3px;
			position: relative;
			font-size: .9rem;
			margin-top: 1rem;
			left: 1.8rem;
			content: "15";
			float: left;
			@include phone-small {
				font-size: .5rem;
				left: 1.3rem;
			}
		}
	}
	
	.sub-controls {
		display: flex;
		flex-direction: row;
	}
	.prev-btn {
		order: 2;
		margin-left: 0rem;
		@include not-phone {
			margin: 2rem 1rem 2rem 1rem;
		}
		@extend %fa-icon;
		@extend .fas;
		&:before {
			content: fa-content($fa-var-backward);
		}
	}
	
	.next-btn {
		order: 6;
		margin-right: 0rem;
		@include not-phone {
			margin: 2rem 1rem 2rem 1rem;
		}
		@extend %fa-icon;
		@extend .fas;
		&:before {
			content: fa-content($fa-var-forward);
		}
	}
	
}

.user {

}

section {
	@include grid-container;
	max-width: $page-width;
	display:block;
// 	margin-left: 10px;
// 	margin-right: 10px;
	padding: 10px 15px 10px 15px;
	@include desktop {
		margin-left: 0px;
		margin-rigth: 0px;
	}
	article {
		margin-top: 1em;

	}
	
	article.lead {
		background-color: white;
		border-left: 10px solid $highlight-color-light;
		padding: 10px 10px 20px 15px;
		margin-right: 2em;
		margin-left: 2em;
		@include tablet {
		}
		p {
			font-size: 125%;
			
			a {
				color: $highlight-color-dark;
			}
		}
	}

	article.feature {
		margin-top: 2em;

		border-radius: $border-radius-huge;
		background-color: white;
		$gradient-background-left: white;
		$gradient-background-right: rgba(255,255,255,0.0);
		$gradient-angle: 90deg;
		border-left: 10px solid $base-color-light;

		background: linear-gradient($gradient-angle, $gradient-background-left 40%, $gradient-background-right), no-repeat $gradient-background-right scroll;
		background-repeat: no-repeat;
		background-size: cover;
		

// 		padding: 0px 0px 0px 15px;
		display: flex;
		justify-content: flex-end;
		@include phone-small {
			width: 106%;
			margin-left: -5%;
		}
		@include desktop {
			max-width: 80%;
			margin-top: 4em;
			margin-left: 6em;
			margin-right: 4em;
			border-left: 20px solid base-color-light !important;
		}
		
		@include desktop-lg {
			max-width: 80%;
			margin-top: 4em;
			margin-left: 6em;
			margin-right: 4em;
			border-left: 20px solid base-color-light !important;
		}
		
		.icon {
			display: flex;
			justify-content: center;
			svg {
				color: $highlight-color;
				@include phone-small {
					font-size: 1.5em;
					padding-top: 10px;
				}
			}
			.fa {
				color: $highlight-color;
				@include phone-small {
					font-size: 1.5em;
					padding-top: 10px;
				}
			}
			
		}

		
		aside {

			display: flex;
			justify-content: center;
			flex-direction: column;
			.description {
				font-size: $font-size-base;
				@include phone-small {
					padding-right: 0px;
				}
				@include padding(1rem);
				color: $font-color-headline;
				@include tablet {
					font-size: $font-size-h3;
					padding: 20px 40px 20px 40px;
				}
				@include desktop {
					padding: 20px 40px 20px 40px;
					font-size: $font-size-h3*1.2;
					line-height: $font-size-h3*1.75;
				}
			}
		}
		figure {
			margin: 0px;
			padding: 0px;
			margin-left: 20px;
			max-width: 35%;
			min-width: 35%;
			@include phone-small {
				margin-left: 7px;
			}
			@include tablet {
				max-width: 20%;
				min-width: 20%;
			}
			@include desktop {
				max-width: 20%;
				min-width: 20%;
			}
			
			img {
				padding: 0px;
				width: 100%;
				margin: 0px;
				float: right;
				@include border-right-radius($border-radius-huge);
			}
		}
		
		figure.video {
			max-width: 50%;
			min-width: 50%;
			
		}
		p {
			font-size: 125%;
			
			a {
				color: $highlight-color-dark;
			}
		}
	}
	
	article.podcast {
		backdrop-filter: blur(0px) brightness(10%);
		
		@include margin(1.5rem .25rem 0rem .25rem);
		@include padding(1rem);
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		flex-basis: auto;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		// background-color: transparentize(black, 0.4);
		p {
			font-size: $font-size-base * 1.1;
		}
		@include tablet {
			@include margin(3rem);
			flex-direction: row;
			justify-content: left;
		}
		@include desktop {
			@include margin(10rem 4rem);
			flex-direction: row;
			justify-content: flex-start;
		}
		
		.title {
			color: $base-color-lighter;
		}
		
		.contact {
			font-size: $font-size-base;
			@include desktop {
				font-size: $font-size-large;
			}
		}
		figure {
			margin: 0px;
			padding: 0px;
			max-width: 100%;
			display: flex;
// 			flex-grow: 1;
			flex: 2 1 40%;
			@include phone-small {
// 				min-width: 40%;
			}
			@include phone {
			}
			@include tablet {
				max-width: 30%;
				min-width: 20%;
			}
			@include desktop {
				max-width: 30%;
				min-width: 20%;
			}
			
			img {
				padding: 0px;
				width: 100%;
				margin: 0px;
			}
		}
		


		.episode {
			margin-bottom: 1rem;
			min-width: 100%;
		
			.audio-player {
				margin: 0px;

				margin-bottom: 1rem;
				@include desktop {
					margin-bottom: 0px;
				}
			}

			
			.player-controls {
				font-family: $font-family-headline;
			}
	
			@keyframes example {
			  from {background-color: none;}
			  to {background-color: $highlight-color-light;}
			}
				
			.trackListing {
				@include padding(.25rem 1rem);
				@include not-phone {
					@include padding(1rem 2rem);
				}
				color: white;
				header {
					color: white;
					font-size: $font-size-h4;
					padding-left: 0px;
					margin-bottom: 1rem;
					margin-left: 0px;
					a {
						color: $highlight-color-light;
					}
					@include tablet {
						font-size: $font-size-h3;
					}
					@include desktop {
						font-size: $font-size-h3;
					}
				}
				// background-color: transparentize($menu-color-dark, 0.1);
				ul {
					padding-left: 1rem;
					list-style-type: none;
					li {
						font-family: $font-family-headline;
						font-size: $font-size-h4;
						line-height: 2rem;
						@include not-phone {
							font-size: $font-size-large;
							line-height: 2.2rem;
						}
						@include tablet {
							font-size: $font-size-large;
							line-height: 1.5rem;
						}
						> a {
							font-family: $font-family-base;
							font-size: $font-size-h4;

							@include padding(0.25rem .5rem);
							&.active {
								animation-name: example;
								animation-duration: 1s;
								animation-fill-mode: forwards;
								border-radius: $border-radius-base;
							}
						}
						
						> .number {
							font-family: $font-family-base;
							font-size: $font-size-large*1.2;
						}
						
					}
	
				}
			}
		}
		
		aside {
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex-grow: 1;
			@include phone-small {
				max-width: 50%;
			}
			@include phone {
				max-width: 50%;
			}
			@include tablet {
			}
			.title {
				margin: 1em;
				h2.title { 
					margin: 0px;
				}
				h3.subtitle {
					margin: 0px;
					margin-bottom: 1em;
					font-weight: 500;
					font-family: $font-family-base;
				}
				a.button {
					margin-left: 0px;
				}
			}

		}
		.description {
			font-size: $font-size-base;
			color: $font-color-headline;
			display: flex;
			flex: 2 1 50%;
			flex-direction: column;
			.title {
				display: none;
				@include not-phone {
					display: inherit;
				}
			}
			@include padding(1rem);
			h3 {
				color: $base-color-light;
				margin-top: 0px;
				margin-bottom: 0px;
			}
			h6 {
				color: $font-color-understate;
				margin-top: 0px;
				font-size: $font-size-h5;
				@include not-phone {
					font-size: $font-size-h4;
				}
			}
			p {
				margin-top: 0px;
				font-size: $font-size-base;
				@include not-phone {
					font-size: $font-size-large;
				}
			}
			@include phone-small {
				padding-right: 0px;
			}
			@include tablet {
				font-size: $font-size-h3;
				@include padding(1rem);
			}
			@include desktop {
				@include padding(1rem);
				font-size: $font-size-h3*1.2;
				line-height: $font-size-h3*1.5;
				max-width: 60%
			}
		}

	}


}

$screenshot-grid-desktop: (
  columns: 4,
  gutter: 50px,
  media: $desktop-width,
);

/******					******/
/******		HOME 		******/
/******					******/

section.home {
	display: flex;
	flex-direction: row;
	flex-basis: auto;
	align-items: center;
	justify-content: space-around;
	@include phone-small {
		flex-wrap: wrap;
	}
	@include phone {
		flex-wrap: wrap;
	}
	
	article.home {
		
		display: flex;
		flex-direction: row;
		flex-basis: auto;
		align-items: center;
		align-self: flex-start;
		box-shadow: 4px 3px 16px transparentize($base-color-light, 0.5);
		background-color: white;
		border-radius: $border-radius-base;
		flex-wrap: wrap;
		@include tablet {
			max-width: 45%;
		}
		@include desktop {
			max-width: 45%;
			margin-left: 0em;
			margin-right: 0em;
		}
		
		header {
			text-align: center;
			.title {
				font-size: $font-size-h2;
				color: $font-color-headline;
				font-weight: bold;
			}
			.subtitle {
				font-size: $font-size-h4;
			}
		}
		aside {

			display: flex;
			justify-content: center;
			flex-direction: column;

			.icon {
				margin-top: 2em;
			}
			.description {
				font-size: $font-size-base;

				@include phone-small {
					padding-right: 0px;
				}
				color: $font-color-headline;
				@include tablet {
					font-size: $font-size-base*1.2;
					padding: 20px 40px 20px 40px;
				}
				@include desktop {
					padding: 20px 40px 20px 40px;
					font-size: $font-size-base*1.2;
					line-height: $font-size-base*2;
					
				}
				.video-single {
					background-color: $base-color;
					width: 100%;
					box-shadow: 4px 3px 6px $base-color-light;
					margin-top: 1em;
					@include grid-column(12);

					@include desktop-lg {
				// 		margin-top: 3em;
					}
					.video-wrapper {
						padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
					}
				}
			}
		}
	}

}


section.screenshots {
// 	display: flex;
	@include grid-container;
	
	margin-top: 3em;
	margin-bottom: 3em;
	@include desktop {
		margin-top: 6em;
		margin-bottom: 6em;
	}
	
	
	.screenshot {
			@include grid-column(6);
			
			@include tablet {
				@include grid-column(3);
	
			}
			@include grid-media($screenshot-grid-desktop) {

				@include grid-column(1);
				h3 {
					font-size: $font-size-h3 * 1.2;
				}
	
			}
		
		img {
			width: 100%;
			height: auto;
// 			border: 2px solid $base-color;
			border-radius: $border-radius-huge;
		}
	}
}

section.home-video {
	margin-top: -25em;
	margin-bottom: 0px;
	@include phone-landscape {
		margin-top: -3.25em;
	}
	@include tablet {
		margin-top: -.985em;
	}
	@include desktop {
		margin-top: -1.25em;
	}
	@include desktop-lg {
		margin-top: -3.25em;
	}
}

/*
$presskit-grid: (
  columns: 6,
  gutter: 60px,
);
*/

/******					******/
/******		PRESS KIT 	******/
/******					******/
section.presskit {
	background-color: white;
	margin-top: 3em;
	border-top: 5px solid $highlight-color-light;
	h2 {
		margin-top: 0px;
		font-weight: 100;
		font-size: $font-size-h2 * 1.2;	
		@include desktop {
			font-size: $font-size-h2 * 1.5;	
		}
		
	}
	
	h3,h4,h5,h6 {
	}
	a {
		color: $highlight-color;
	}
	
	
	a:hover {
		color: $highlight-color-light;
	}

	a:hover.button {
		color: white;
	}
	
	article {
		@include grid-column(12);

		
		@include tablet {
			@include grid-column(4);
		}
		
		@include desktop {
			@include grid-column(4);
		}
		header {
			
		}
		
		h3 {
			font-family: $font-family-sans-serif2;
			@include desktop {
				font-size: 1.5em;
			}
		}
		.file{
			color: $base-color;
			padding-bottom: 0px;
			margin-bottom: 0px;

			i {
				padding-bottom: 0px;
				margin-bottom: 0px;
			}
		}
		
		img {
		}
	}
	
	
}

section.pressvideo {
	article {
		display: block;
		@include grid-column(12);
		@include desktop {
			@include grid-column(12);
// 			@include grid-push(1);
		}
	}
}

section.pressscreenshots {
	
	.screenshot {
		h3 {
			margin: 0px auto;
			text-align: center;
			width: 100%;
			padding: 0px;
			font-size: $font-size-h3 * 1.1;
			a {
				color: $highlight-color;
			} 
			a:hover {
				color: $highlight-color-light;
			}
		}
	
		@include grid-column(4);
		
		@include tablet {
			@include grid-column(3);

		}
		@include desktop {
			@include grid-column(2);
			h3 {
				font-size: $font-size-h3 * 1.2;
			}

		}
		img {
			width: 100%;
			height: auto;
// 			border: 2px solid $base-color;
			border-radius: $border-radius-large;
		}
		margin-bottom: 3em;
	}
}


.hidden {
	visibility: hidden;
}

.iframe-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 75%; // For ratio 16:9. 75% if ratio is 4:3
  position: relative;

  embed,
  object,
  iframe {
    @include position(absolute, 0 null null 0);
    @include size(100%);
  }
}


/******					******/
/******		VIDEO 		******/
/******					******/


.video {
	background-color: $sec-color;
	display: block;
	margin-top: -25em;
	margin-bottom: -10em;
	padding-top: 0em;
	padding-bottom: 80%;
	@include phone-small {
		margin-top: -27em;
	}
	@include phone-landscape {
		margin-top: -3em;
	}
	@include tablet {
		margin-top: -5em;
		padding-top: 3em;
		padding-bottom: 50%;
	}
	@include desktop {
		margin-top: -3em;
		padding-bottom: 52%;
	}
	@include desktop-lg {
		margin-top: -3em;
		padding-top: 20px;
		padding-bottom: 51%;
	}
	.video-layout{
		@include grid-column(12);  
		@include tablet {
			h3 {
				font-size: 2em;
				margin-top: 0;
			}
			@include grid-column(8);
			@include grid-push(2);
		}                    
		@include desktop {
			h3 {
				font-size: 2.5em;
			}
			@include grid-column(8);
			@include grid-push (2);
		}
	}

	.video-player {
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
		position: relative;
		box-shadow: 4px 3px 23px $base-color-light;
		embed,
		object,
		iframe {
			@include position(absolute, 0 null null 0);
			@include size(100%);
		}
	}
}
.video-single {
// 	background-color: $base-color;
	display: block;
	width: 100%;
// 	box-shadow: 4px 3px 6px $base-color-light;
	margin-top: 1em;
	@include grid-column(12);
	@include desktop {
		@include grid-column(6);
		@include grid-push(3);
	}
	@include desktop-lg {
// 		margin-top: 3em;
	}
	.video-title {
		padding: 5px 0px 5px 0px;

		h2,h3,h4,h5 {
// 			color: $base-color-dark;
// 			text-shadow:2px 1px 0px $base-color-light;
		}
		h2 {
			font-weight: normal;
			margin-bottom: 5px;
		}
		p {
			margin-top: 0px;
		}
		@include phone-landscape {
			padding: 5px 0px 7px 0px;
			h2 {
				font-size: $font-size-h2 * 1.1;
			}
		}
		@include tablet {
			padding: 5px 0px 7px 0px;
			h2 {
				font-size: $font-size-h2 * 1.5;
				margin-bottom: 0px;

			}
			p {
				font-size: $font-size-large;
				margin-top: 0px;
			}
		}
		@include desktop {
			padding: 10px 0px 10px 0px;
			h2 {
				font-size: $font-size-h2 * 2;
				margin-bottom: 5px;
			}
			p {
				font-size: $font-size-large;
			}
		}
	}
	.video-wrapper {
		height: 0;
// 		overflow: hidden;
		padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
		position: relative;

		embed,
		object,
		iframe {
			@include position(absolute, 0 null null 0);
			@include size(100%);
		}
	}

}

/******					******/
/****** 	DEVICE 		******/
/******					******/


.device-background {
	$base-border-radius: 3px !default;
	$action-color: $base-color-light !default;
	$device-padding-vertical: 5em;
	$device-padding-horizontal: 0.4em;
	$device-screen-width: 14em;
	$device-screen-height: 25em;
	$device-background: darken(gray, 40%);
	$device-backside-background: darken($device-background, 10%);
	$device-text-color: white;
	$device-background-top: $base-color-light;
	$device-background-bottom: $base-color;
	$gradient-angle: 10deg;
	$device-image: "";
	$device-screen-image: "/assets/img/snapar/screenshots/v2-Play-Birthday-reduced.png";
	background: 
	linear-gradient($gradient-angle, $device-background-bottom, $device-background-top),
	no-repeat $device-background-top scroll;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	
	@include phone-small {
		min-height: 60em;
	}
	@include phone {
		min-height: 44em;
	}
	@include phone-landscape {
		min-height: 52em;
	}
	@include tablet {
		min-height: 56em;
	}
	@include desktop {
// 		border-radius: 10px;
		min-height: 40em;
	}
	@include desktop-lg {
		min-height: 35em;
	}
	.device-text {
		//padding: 2em;
		background: 
	linear-gradient($gradient-angle, $device-background-bottom, $device-background-top),
	no-repeat $device-background-top scroll;
		float: left;
		max-width: 100%;
		padding: 2em;
		padding-right: 1.5em;
		padding-left: 1.5em;
		margin-right: 0px;
		@include phone-landscape {
			max-width: 40%;
			background: none;

		}
		@include tablet {
			max-width: 45%;
			background: none;
		}
		
		@include desktop {
			background: none;
			padding: 3em;
			max-width: 50%;	
		}
		
		@include desktop-lg {
			padding: 4em;
			max-width: 50%;
		}

		h4 {
			border-bottom: 1px solid transparentize($device-text-color, 0.7);
			color: $device-text-color;
			font-size: 1.5em;
			margin: 0 0 0.5em 0;
			padding-bottom: 0.5em;
			letter-spacing: 2px;

		}
		
		p {
			color: $device-text-color;
			font-size: 1em;
			line-height: 1.5em;
			margin-bottom: 1.5em;
			@include phone-landscape {
				font-size: 1.1em;
			}
			@include tablet {
				font-size: 1.1em;

			}
			@include desktop {
				font-size: 1.1em;
			}
		}
		
		p.more-text {
			width: 100%;
			@include phone-landscape {
				width: 200%;
			}
			@include tablet {
				width: 100%;
			}
			@include desktop {
				width: 100%;
			}
		}
	}
	
	.device {
		
		//display: none;
		position: relative;
		display: inline-block;
		float: right;
		background: $device-background;
		border-radius: 2em;
		box-shadow:
		1px 0 lighten($device-backside-background, 20%),
		2px 0 lighten($device-backside-background, 2%),
		5px 0 $device-backside-background,
		8px 0 $device-backside-background,
		9px 0 $device-backside-background;
		display: block;
		float: right;
		margin-bottom: .5em;
		margin-right: 3em;
		padding: calc($device-padding-vertical/1.5) 0;
		width: calc($device-screen-width/1.5 + (2 * $device-padding-horizontal));
		transform:
		perspective(800px)
		translateX(15px)
		translateY(-10px)
		translateZ(50px)
		rotateX(0)
		rotateY(-20deg)
		rotateZ(0)
		;
		@include phone-small {
			margin-left: 2em;
			margin-bottom: 1em;
			transform:
			perspective(800px)
			translateX(-10px)
			translateY(-10px)
			translateZ(50px)
			rotateX(0)
			rotateY(-20deg)
			rotateZ(0)
			;
		}
		
		@include phone {
			margin-left: 0em;
			margin-bottom: 1em;
			transform:
			perspective(800px)
			translateX(25px)
			translateY(10px)
			translateZ(50px)
			rotateX(0)
			rotateY(-20deg)
			rotateZ(0)
			;
		}

		@include phone-landscape {
			float: right;
			background: $device-background;
			border-radius: 2em;
			box-shadow:
			1px 0 lighten($device-backside-background, 20%),
			2px 0 lighten($device-backside-background, 2%),
			5px 0 $device-backside-background,
			8px 0 $device-backside-background,
			9px 0 $device-backside-background;
			display: block;
			float: right;
			margin-bottom: -9em;
			margin-right: 3em;
			padding: calc($device-padding-vertical/1.5) 0;
			width: calc($device-screen-width/1.5 + (2 * $device-padding-horizontal));
			transform:
			perspective(800px)
			translateX(-15px)
			translateY(25px)
			translateZ(50px)
			rotateX(0)
			rotateY(-20deg)
			rotateZ(0)
			;
		}
		@include tablet {
			float: right;
			background: $device-background;
			border-radius: 2em;
			box-shadow:
			1px 0 lighten($device-backside-background, 20%),
			4px 0 lighten($device-backside-background, 2%),
			7px 0 $device-backside-background,
			10px 0 $device-backside-background,
			13px 0 $device-backside-background;
			display: block;
			float: right;
			margin-bottom: -12em;
			margin-right: 5em;
			padding: $device-padding-vertical 0;
			width: $device-screen-width + (2 * $device-padding-horizontal);
			transform:
			perspective(800px)
			translateX(0)
			translateY(-10px)
			translateZ(50px)
			rotateX(0)
			rotateY(-20deg)
			rotateZ(0)
			;
		}

		@include desktop {
			float: right;

			background: $device-background;
			border-radius: 2em;
			box-shadow:
			1px 0 lighten($device-backside-background, 20%),
			4px 0 lighten($device-backside-background, 2%),
			7px 0 $device-backside-background,
			10px 0 $device-backside-background,
			13px 0 $device-backside-background;
			display: block;
			float: right;
			margin-bottom: -12em;
			margin-right: 5em;
			padding: $device-padding-vertical 0;
			width: $device-screen-width + (2 * $device-padding-horizontal);
			transform:
			perspective(800px)
			translateX(0)
			translateY(-50px)
			translateZ(50px)
			rotateX(0)
			rotateY(-20deg)
			rotateZ(0)
			;
		}

		.screen {
			@include size(calc($device-screen-width/1.5), calc($device-screen-height/1.5));
			@include phone-landscape {
				@include size(calc($device-screen-width/1.5), calc($device-screen-height/1.5));
			}
			@include tablet {
				@include size($device-screen-width, $device-screen-height);
			}
			@include desktop {
				@include size($device-screen-width, $device-screen-height);
			}
			background-image: url($device-screen-image);
			background-size: cover;
			border-radius: 0.2em;
			box-shadow: inset 0 1px 8px transparentize(black, 0.5);
			margin: auto;
			z-index: 501 !important;

		}
			
	}
}


/******					******/
/****** 	SIDE IMAGE 	******/
/******					******/

section.sideimage {
}
.side-image {
	$base-line-height: 1.5em;
	$action-color: $highlight-color !default;
	$side-image-background-top: $sec-color;
	$side-image-background-bottom: $sec-color-dark;
	$side-image-content-background: $sec-color-light;
	$side-image-border: 1px solid darken(transparentize($side-image-background-bottom, 0.8), 30%);
	background: $side-image-content-background;
	
	.images-wrapper {
		background: url("/assets/img/snapar/screenshots/v2-Play-Birthday-reduced.png"),
		            linear-gradient($side-image-background-top, $side-image-background-bottom),
		            no-repeat $side-image-background-top scroll;
		background-color: $side-image-background-top;
		background-size: cover;
		border-bottom: $side-image-border;
		display: block;
		min-height: 12em;
		padding: 3em;
	
		@include grid-column(3);
		@include desktop {
			@include grid-column(3);
			min-height: 28em;
			background-position: bottom;
			background-size: cover;
			border-right: $side-image-border;
		}
		margin-left:0px !important;
	}
	
	.side-image-content {
		@include grid-column(5);
		padding: 1em 1em 4em 1em;
		
		@include desktop-lg {
		@include grid-column(6);
		padding: 4em 4em 8em 4em;
		}
		
		h1 {
		margin-bottom: 0.9em;
		}
		
		h4 {
		background: #BBB;
		color: white;
		display: inline-block;
		font-size: 0.75em;
		margin-bottom: 1.3em;
		padding: 3px 8px;
		text-transform: uppercase;
		}
		
		p {
		color: $base-color;
		line-height: $base-line-height;
		margin-bottom: 3em;
		}
	}

	@include clearfix;
}

.contact {
	
	#thanks {
		display: none;
		font-size: $font-size-h3;
		padding-left: 25px;
		padding-right: 25px;
		
	}

	margin: 0px auto;
	@include desktop {
		width: 60%;
	}
	
	@include desktop-lg {
		width: 50%;
	}
	
	input#preferredKitten { display: none; }
	
	#contact-form {
		//background:#F9F9F9;
		padding:25px;
		margin:20px 0 0 0;
	}
	
	#contact-form h3 {
		color: #F96;
		display: block;
		font-size: 30px;
		font-weight: 400;
	}
	
	#contact-form h4 {
		margin:5px 0 15px;
		display:block;
		font-size:13px;
	}
	
	fieldset {
		border: medium none !important;
		margin: 0 0 10px;
		min-width: 90%;
		padding: 0;
		width: 95%;
	}
	
	#contact-form input[type="text"], #contact-form input[type="email"], #contact-form input[type="tel"], #contact-form input[type="url"], #contact-form textarea {
		width:100%;
		border:1px solid #CCC;
		background:#FFF;
		margin:0 0 5px;
		padding:10px;
	}
	
	#contact-form input[type="text"]:hover, #contact-form input[type="email"]:hover, #contact-form input[type="tel"]:hover, #contact-form input[type="url"]:hover, #contact-form textarea:hover {
		-webkit-transition:border-color 0.3s ease-in-out;
		-moz-transition:border-color 0.3s ease-in-out;
		transition:border-color 0.3s ease-in-out;
		border:1px solid #AAA;
	}
	
	#contact-form textarea {
		height:100px;
		max-width:100%;
	  resize:none;
	}
	
	::-webkit-input-placeholder {
		color:#888;
	}
	:-moz-placeholder {
		color:#888;
	}
	::-moz-placeholder {
		color:#888;
	}
	:-ms-input-placeholder {
		color:#888;
	}
}




@include phone {
	.visible-phone {
		visibility: visible;
	}
	
	.visible-phone-landscape {
		visibility: hidden;
		display: none;
	}
	
	.visible-tablet {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop-lg {
		visibility: hidden;
		display: none;
	}
	
	.hidden-phone {
		visibility: hidden;
	}
		
	.hidden-phone-landscape {
		visibility: visible;
	}
	
	.hidden-tablet {
		visibility: visible;
	}
	
	.hidden-desktop {
		visibility: visible;
	}
		
	.hidden-desktop-lg {
		visibility: visible;
	}
}

@include phone-landscape {
	.visible-phone {
		visibility:hidden;
		display: none;
	}
	
	.visible-phone-landscape {
		visibility: visible;
	}
	
	.visible-tablet {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop {
		visibility:hidden;
		display: none;
	}
	
	.visible-desktop-lg {
		visibility: hidden;
		display: none;
	}
	
	.hidden-phone {
		visibility: visible;
	}
		
	.hidden-phone-landscape {
		visibility: hidden;
	}
	
	.hidden-tablet {
		visibility: visible;
	}
	
	.hidden-desktop {
		visibility: visible;
	}
		
	.hidden-desktop-lg {
		visibility: visible;
	}
}

@include tablet {
	.visible-phone {
		visibility: hidden;
		display: none;
	}
	
	.visible-phone-landscape {
		visibility: hidden;
		display: none;
	}
	
	.visible-tablet {
		visibility: visible;
	}
	
	.visible-desktop {
		visibility:hidden;
		display: none;
	}
	
	.visible-desktop-lg {
		visibility: hidden;
		display: none;
	}
	
	.hidden-phone {
		visibility: visible;
	}
	
	.hidden-phone-landscape {
		visibility: visible;
	}
	
	.hidden-tablet {
		visibility: hidden;
	}
	
	.hidden-desktop {
		visibility: visible;
	}
		
	.hidden-desktop-lg {
		visibility: visible;
	}
}

@include desktop {
	.visible-phone {
		visibility:hidden;
		display: none;
	}
	
	.visible-phone-landscape {
		visibility: hidden;
		display: none;
	}
	
	.visible-tablet {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop {
		visibility: visible;
	}
	
	.visible-desktop-lg {
		visibility: hidden;
		display: none;
	}
		
	.hidden-phone {
		visibility: visible;
	}
	
	.hidden-phone-landscape {
		visibility: visible;
	}
	
	.hidden-tablet {
		visibility: visible;
	}
	
	.hidden-desktop {
		visibility:hidden;
		
	}
	
	.hidden-desktop-lg {
		visibility:visible;
	}

}

@include desktop-lg {
	.visible-phone {
		visibility:hidden;
		display: none; 
	}
	
	.visible-phone-landscape {
		visibility: hidden;
		display: none;
	}
	
	.visible-tablet {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop {
		visibility: hidden;
		display: none;
	}
	
	.visible-desktop-lg {
		visibility: visible;
	}
		
	.hidden-phone {
		visibility: visible;
	}
	
	.hidden-phone-landscape {
		visibility: visible;
	}
	
	.hidden-tablet {
		visibility: visible;
	}
	
	.hidden-desktop {
		visibility: visible;
	}
	
	.hidden-desktop-lg {
		visibility: hidden;
	}

}


