@mixin phone-small {
	@media (max-width: #{$phone-small - 1px}) {
		@content;
	}
}

@mixin phone {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin not-phone {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}

@mixin phone-landscape {
	@media (min-width: #{$phone-landscape-width}) and (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content; 
	}
}

@mixin desktop-lg {
	@media (min-width: #{$desktop-large-width}) {
		@content; 
	}
}

@mixin container {
	max-width: $page-width;
	margin-left: 10px;
	margin-right: 10px;
	@include desktop {
		margin: 0px auto;
		width: 100%;
	}
}

@mixin iphone-notch {
	padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom);
}

@mixin iphone-notch-left {
	padding-left: env(safe-area-inset-left);
}

@mixin action-border {
	border: $action-border;
	border-radius: $action-border-radius;
}
@mixin action-border-light {
	border: $action-border-light;
	border-radius: $action-border-radius;
}

@mixin action-border-light-transparent {
	border: $action-border-light-transparent;
	border-radius: $action-border-radius;
}
@mixin action-border-dark {
	border: $action-border-dark;
	border-radius: $action-border-radius
}
@mixin action-border-dark-transparent {
	border: $action-border-dark-transparent;
	border-radius: $action-border-radius;
}

@mixin action-border-thin {
	border: $action-border-thin;
	border-radius: $action-border-radius;
}

@mixin action-border-thin-dark {
	border: $action-border-thin-dark;
	border-radius: $action-border-radius;
}
