
//Font-Awesome
//fontawesome.com
$fa-font-path: "/assets/webfonts";

$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$phone-small: 321px;
$phone-landscape-width: 450px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-large-width: 1250px;

$font-family-dc: "komu-a",sans-serif;
$font-family-sans-serif: "ff-enzo-web",sans-serif;
$font-family-sans-serif2: "ff-meta-web-pro", sans-serif;
$font-family-apple: -apple-system, BlinkMacSystemFont, sans-serif;
$font-family-headline: $font-family-sans-serif;
$font-family-title: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif2;
$menu-font-family: $font-family-sans-serif2;

$font-size-base:          14px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-nav:			floor(($font-size-base * 1.7)); // ~36px

$font-size-h1:			floor(($font-size-base * 4.7)); // ~36px
$font-size-h2:			floor(($font-size-base * 2.55)); // ~30px
$font-size-h3:			ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:			ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:			$font-size-base;
$font-size-h6:			ceil(($font-size-base * 0.85)); // ~12px
$font-size-card-header:	$font-size-h2;
$font-size-card-copy:	$font-size-h4;
$line-height-base:        1.428571429; // 20/14
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

$border-radius-base: 2px;
$border-radius-small: 1px;
$border-radius-large: 4px; 
$border-radius-huge: 8px; 

$blue: rgb(42, 51, 86);
$light-blue: rgb(217, 217, 225);
$green: rgb(25, 204, 25);
$red: rgb(178, 26, 26);
$gray: #ccccdd;
$darkgray: #444444;

$base-color: $blue;
$base-color-dark: shade($base-color, 30%);
$base-color-light: tint($base-color, 30%);
$base-color-lighter: tint($base-color, 60%);

$sec-color: $light-blue;
$sec-color-dark: shade($sec-color, 50%);
$sec-color-light: tint($sec-color, 80%);
$sec-color-lighter: tint($sec-color, 90%);

$highlight-color: $green;
$highlight-color-dark: shade($highlight-color, 10%);
$highlight-color-light: tint($highlight-color, 70%);

$action-color: $blue;
$action-color-dark: shade($action-color, 30%);
$action-color-light: tint($action-color, 50%);
$action-color-lighter: tint($action-color, 90%);

$background-color: tint($highlight-color, 95%);
$font-color-base: $sec-color-dark;
$font-base-color: $font-color-base;
$font-color-headline: $base-color;
$font-color-understate: $base-color-lighter;
$font-action-color: $base-color-light;
$font-color-light: $base-color-lighter;
$page-width: 1400px;

$menu-color: tint($background-color, 50%);
$menu-color-dark: shade($base-color, 50%);
$menu-font-color: $font-color-base;
$menu-icon-color: $sec-color-dark;
$neat-grid: (
  columns: 12,
  gutter: 20px,
);

// Border
$base-border-width: 1px;
$sec-border-width: $base-border-width;
$action-border-width: $base-border-width+2;
$highlight-border-width: $base-border-width;
$error-border-width: $base-border-width;

$base-border-radius: 3px;
$large-border-radius: 5px; 
$sec-border-radius: $base-border-radius;
$action-border-radius: $base-border-radius;
$highlight-border-radius: $base-border-radius;
$error-border-radius: $base-border-radius;

$action-border: $action-border-width solid $action-color;
$action-border-transparent: $action-border-width solid transparentize($action-color, 0.9);
$action-border-light: $action-border-width solid $action-color-light;
$action-border-light-transparent: $action-border-width solid transparentize($action-color-light, 0.9);
$action-border-dark: $action-border-width solid $action-color-dark;
$action-border-dark-transparent: $action-border-width solid transparentize($action-color-dark, 0.9);
$action-border-thin: $action-border-width/2 solid $action-color-light;
$action-border-thin-transparent: $action-border-width/2 solid transparentize($action-color-light, 0.9);
$action-border-thin-dark: $action-border-width/2 solid $action-color-dark;

